<template>
  <div class="room-page">
    <div v-if="loading" class="container">
      <div class="section-banner">
        <div class="col-title">
          <div class="block-bg">
            <h4>HABITACIONES<br>{{ selectedRoom.name }}</h4>
          </div>
        </div>
        <div class="col-image">
          <img :src="selectedRoom.images[0]" alt="">
        </div>
      </div>
      <div class="section-list">
        <div class="room">
          <div class="room-detail">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="room-detail--description">
                  <h3>{{ selectedRoom.name }}</h3>
                  <p><strong>DESCRIPCIÓN</strong></p>
                  <p>{{ selectedRoom.text1 }}</p>
                  <p>{{ selectedRoom.text2 }}</p>
                </div>
                <div class="room-detail--description">
                  <p><strong>SERVICIOS</strong></p>
                  <div class="row">
                    
                    <div class="col-md-3" v-for="index in selectedRoom.amenities" :key="index.description">
                      <ul>
                        <li style="list-style: none;"><i :class="index.icon"></i> {{index.description}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-10 offset-md-1">
                <div class="room-detail--slider">
                  <div class="slider">
                    <vue-tiny-slider
                      ref="vmslider"
                      :mouse-drag="isMobileDevice || isTabletDevice"
                      :nav="false"
                      :controls="false"
                      :loop="true"
                      items="1"
                      mode="gallery"
                    >
                      <div
                        class="slider__item"
                        v-for="(item, index)  in selectedRoom.images"
                        :key="`slider_${index}`"
                        :style="`background-image: url(${item})`"
                      >
                      </div>
                    </vue-tiny-slider>
                    <div class="slider__controls">
                      <div class="container">
                        <button type="button" class="control-custom control-left" @click="onPrevStep">
                          <img src="@/assets/icon-left-arrow.png" />
                        </button>
                        <button type="button" class="control-custom control-right" @click="onNextStep">
                          <img src="@/assets/icon-right-arrow.png" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueTinySlider from 'vue-tiny-slider'
import roomList from '@/utils/rooms'

export default {
  name: 'RoomDetailPage',
  components: { VueTinySlider },
  data: () => ({
    roomList,
    sliderIndex: 1,
    loading: false,
    selectedRoom: {}
  }),
  created() {
    const { params } = this.$route
    if (!this.isEmpty(params) && !this.isEmpty(params.room)) {
      this.selectedRoom = roomList.find(e => e.name === params.room)
      this.loading = true
    } else {
      this.$router.push({ name: 'RoomPage' })
    }
  },
  methods: {
    
    onNextStep() {
      console.log('next')
      this.$refs.vmslider.slider.goTo('next')
      const { displayIndex } = this.$refs.vmslider.slider.getInfo()
      this.sliderIndex = displayIndex
    },
    onPrevStep() {
      console.log('prev')
      this.$refs.vmslider.slider.goTo('prev')
      const { displayIndex } = this.$refs.vmslider.slider.getInfo()
      this.sliderIndex = displayIndex
    },
  }
}
</script>
